import dayjs from 'dayjs'
import { computed, ref } from '@vue/composition-api'
import { capitalizeFirstLetter, cloneNested } from '@/helpers/helpers'
import store from '@/store/store'
import { $themeBreakpoints } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'
import _ from 'lodash'

export default () => {
  const refreshKey = ref(0)
  const selectedDates = ref([])
  const availabilities = ref({})
  const currentMonth = ref(dayjs().add(1, 'month').startOf('month'))
  const userHasRolesToSchedule = computed(() => {
    const myRoles = store.getters['auth/getUserRawRoles']
    const rolesForScheduling = [
      ...store.getters['appConfig/config'].schedule.userRolesForScheduling,
      ...['ROLE_DRIVER', 'ROLE_PARAMEDIC'],
    ]

    return rolesForScheduling.some(role => myRoles.includes(role))
  })

  const dayjsWeekdays = computed(() => {
    const weekdays = cloneNested(dayjs.localeData().weekdays())
    if (dayjs.localeData().firstDayOfWeek() === 1) {
      weekdays.push(weekdays.shift())
    }
    return weekdays
  })

  const scheduleConfig = computed(() => {
    if (store.getters['appConfig/config'].schedule) {
      return store.getters['appConfig/config'].schedule
    }

    return {}
  })

  const shiftDefinitions = computed(() => {
    const definitions = {}
    scheduleConfig.value.shiftDefinitions.forEach(shiftDefinition => {
      definitions[shiftDefinition.shiftDefinitionIdentifier] = shiftDefinition
    })
    return definitions
  })

  const fetchAvailabilities = () => {
    store.dispatch('schedules/fetchMyAvailabilities', currentMonth.value)
      .then(response => {
        const newAvailabilities = {}
        response.data.forEach(availability => {
          const date = dayjs(availability.date).format('YYYY-MM-DD')
          if (!newAvailabilities[date]) {
            newAvailabilities[date] = {}
          }

          const definitionId = availability.shiftDefinitionIdentifier
          const newAvailability = _.cloneDeep(shiftDefinitions.value[definitionId])
          newAvailability.id = availability.id
          newAvailability.shiftDefinitionIdentifier = definitionId
          newAvailability.isPlanned = availability.isPlanned
          newAvailabilities[date][definitionId] = newAvailability
        })
        availabilities.value = newAvailabilities
      })
      .catch(err => {
        window.toast.notify.danger(err.message)
        throw err
      })
  }
  fetchAvailabilities()

  const periodStart = computed(() => currentMonth.value.startOf('week'))
  const periodEnd = computed(() => currentMonth.value.add(1, 'month').endOf('week'))

  const monthArray = computed(() => {
    let date = periodStart.value.clone()
    let week = {}
    const month = []

    while (date < periodEnd.value) {
      week = {
        number: date.week(),
        days: [],
      }
      for (let i = 0; i < 7; i += 1) {
        week.days.push({
          date,
          weekday: date.weekday(),
          formatted: date.format('YYYY-MM-DD'),
          isToday: date.isToday(),
          isCurrentMonth: currentMonth.value.format('YYYYMM') === date.format('YYYYMM'),
          isPast: date.isBefore(dayjs(), 'day'),
        })

        date = date.add(1, 'day')
      }
      month.push(week)
      week = []
    }

    return month
  })

  const isSelected = date => selectedDates.value.indexOf(date) !== -1
  const isCurrentMonth = date => currentMonth.value.format('YYYYMM') === dayjs(date).format('YYYYMM')

  const toggle = (date, newValue = null) => {
    if (isCurrentMonth(date) === false) {
      return
    }
    if (newValue === true) {
      if (!isSelected(date)) {
        selectedDates.value.push(date)
      }
      return
    }

    if (newValue === false) {
      if (isSelected(date)) {
        selectedDates.value.splice(selectedDates.value.indexOf(date), 1)
      }
      return
    }

    if (selectedDates.value.indexOf(date) === -1) {
      selectedDates.value.push(date)
    } else {
      selectedDates.value.splice(selectedDates.value.indexOf(date), 1)
    }
  }

  const toggleWeek = weekNumber => {
    let newValue = null
    monthArray.value.forEach(week => {
      if (week.number === weekNumber) {
        week.days.forEach(day => {
          if (isCurrentMonth(day.formatted)) {
            if (newValue === null) {
              newValue = !isSelected(day.formatted)
            }
            toggle(day.formatted, newValue)
          }
        })
      }
    })
  }

  const toggleDay = dayNumber => {
    let newValue = null
    monthArray.value.forEach(week => {
      week.days.forEach(weekDay => {
        if (isCurrentMonth(weekDay.formatted)) {
          if (weekDay.date.weekday() === dayNumber) {
            if (newValue === null) {
              newValue = !isSelected(weekDay.formatted)
            }
            toggle(weekDay.formatted, newValue)
          }
        }
      })
    })
  }

  const addAvailability = shiftDefinition => {
    const payload = []
    selectedDates.value.forEach(date => {
      if (!availabilities.value[date]) {
        availabilities.value[date] = {}
      }
      if (!availabilities.value[date][shiftDefinition.shiftDefinitionIdentifier]) {
        availabilities.value[date][shiftDefinition.shiftDefinitionIdentifier] = _.cloneDeep(shiftDefinition)

        payload.push({
          date,
          shiftDefinitionIdentifier: shiftDefinition.shiftDefinitionIdentifier,
        })
      }
    })
    store.dispatch('schedules/addAvailabilities', payload)
      .then(response => {
        response.data.forEach(availability => {
          const date = dayjs(availability.date).format('YYYY-MM-DD')
          availabilities.value[date][availability.shiftDefinitionIdentifier].id = availability.id
        })
        refreshKey.value += 1
      })
      .catch(err => {
        window.toast.notify.danger(err.message)
        selectedDates.value.forEach(date => {
          delete availabilities.value[date][shiftDefinition.id]
        })
      })
    refreshKey.value += 1
  }

  const removeAvailability = (dateFormatted, shiftDefinitionIdentifier) => {
    const { id } = availabilities.value[dateFormatted][shiftDefinitionIdentifier]
    delete availabilities.value[dateFormatted][shiftDefinitionIdentifier]
    if (id) {
      store.dispatch('schedules/deleteAvailabilities', [id])
    }
    refreshKey.value += 1
  }

  const clearCurrentMonth = (softClear = true) => {
    if (!softClear) {
      const ids = []
      Object.keys(availabilities.value).forEach(date => {
        if (isCurrentMonth(date)) {
          Object.keys(availabilities.value[date]).forEach(shiftDefinitionIdentifier => {
            if (availabilities.value[date][shiftDefinitionIdentifier].id) {
              ids.push(availabilities.value[date][shiftDefinitionIdentifier].id)
            }
          })
        }
      })
      store.dispatch('schedules/deleteAvailabilities', ids)
        .then(() => {
          fetchAvailabilities()
          refreshKey.value += 1
        })
        .catch(err => {
          window.toast.notify.danger(err.message)
          throw err
        })
    }
    selectedDates.value = []
    availabilities.value = {}
  }

  const nextMonth = () => {
    clearCurrentMonth()
    currentMonth.value = currentMonth.value.add(1, 'month')
    fetchAvailabilities()
  }

  const prevMonth = () => {
    clearCurrentMonth()
    currentMonth.value = currentMonth.value.subtract(1, 'month')
    fetchAvailabilities()
  }

  const { skin } = useAppConfig()
  const isMobileWidth = computed(() => $themeBreakpoints.lg > store.state.app.windowWidth)
  const isDark = computed(() => skin.value === 'dark')

  return {
    currentMonth,
    userHasRolesToSchedule,
    dayjsWeekdays,
    monthArray,
    selectedDates,
    availabilities,
    isSelected,
    toggle,
    toggleWeek,
    toggleDay,
    addAvailability,
    removeAvailability,
    clearCurrentMonth,
    refreshKey,
    nextMonth,
    prevMonth,

    scheduleConfig,

    dayjs,
    capitalizeFirstLetter,
    isMobileWidth,
    isDark,
  }
}
